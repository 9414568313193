.headerBox {
  display: flex;
  margin: 0 auto;
  width: 1200px;
  color: #000;
}

.navTab > span {
  cursor: pointer;
  margin-right: 40px;
}

.footIntroduce {
  background: #24252e;
  padding: 20px 0;
  color: #fff;
  border-top: 6px solid #c7000b;
}

.footWords {
  width: 100%;
  text-align: center;
  background: #2c2d37;
  padding: 20px 0;
}

.footMain {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.footerInfo {
  display: flex;
  width: 900px;
  justify-content: space-around;
}

.footerInfo > div {
  flex: 1;
}

.footerInfo > div > div:nth-child(1) {
  font-size: 16px;
  margin-bottom: 10px;
}

.footerInfo > div > div:nth-child(2) > div {
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}

.contactWay > div {
  line-height: 28px !important;
  font-size: 16px !important;
}

.contactWay > div > span:nth-child(2) {
  margin: 0 10px;
}

.contactWay > div > span:nth-child(3) {
  color: #fff;
}

.qrCode {
  width: 100px;
  text-align: 'right';
}

.footerLogo {
  width: 300px;
  text-align: left;
  border-right: 1px solid #3c3d48;
  margin-right: 70px;
}

.footWords div {
  font-size: 12px;
  color: #999999;
  font-family: 'MicrosoftYaHei';
  line-height: 20px;
}

.aLink {
  color: #999999;
  cursor: pointer;
}

.aLink:hover {
  color: #999999;
}

.ant-layout-footer {
  padding: 10px 50px !important;
}

.tabLIne {
  border-bottom: 3px solid #f00;
  padding-bottom: 20px;
}

.bottomBox {
  width: 68px;
  height: 68px;
  margin-top: 10px;
  text-align: center;
  background: #fff;
  padding-top: 10px;
  box-shadow: 1px 2px 3px 0px rgba(182, 0, 6, 0.1);
  font-size: 12px;
  cursor: pointer;
}

.bottomIcon {
  height: 30px;
  display: block;
  margin: 0 auto 5px;
}

.bottomTitle {
  text-align: center;
}
