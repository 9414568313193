/* @import "~antd/dist/antd.css"; */
@import "./styles/antd-custom.scss";
@import "./styles/ie.scss";

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;

  font-size: 16px !important;
}
